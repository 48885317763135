import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import "./i18n";
import Layout from "./pages/Layout";
import "./App.css";
import HomePage from "./pages/Home/HomePage";
import DetailsPage from "./pages/DetailList/DetailList";
import HouseDetail from "./pages/HouseDetail/HouseDetail";
import About from "./pages/Home/Footer/pages/About";
import Setting from "./pages/Setting/Setting";
import BookMark from "./pages/BookMark/BookMark";
import ApprovedBook from "./pages/BookMark/ApprovedBook/ApprovedBook";
import FilterResults from "./pages/FilterResult/FilteredResults";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Layout />}>
      <Route index element={<HomePage />} />
      <Route path="/lists/:catagory" element={<DetailsPage />} />
      <Route path="/detail/:id" element={<HouseDetail />} />
      <Route path="/about" element={<About />} />
      <Route path="/setting" element={<Setting />} />
      <Route path="/bookmark" element={<BookMark />} />
      <Route path="/approvedDetail/:id" element={<ApprovedBook />} />
      <Route path="/filtered-results" element={<FilterResults />} />
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
