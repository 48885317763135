import React, { useEffect, useState } from "react";
import "../../App.css";
import "./BookMark.css";
import TelegramBackButton from "../../components/TelegramBackButton.jsx";
import NoResult from "../../assets/inbox_empty.png";
import defaultImage from "../../assets/default.png";
import { API_BASE_URL, API_KEY } from "../../config.js";
import { Link } from "react-router-dom";
import TelegramUser from "../../TelegramUser.js";
import LoadingAnim from "../../components/LoadingAnim.jsx";
import { useTranslation } from "react-i18next";

const ITEMS_PER_PAGE = 10;

const BookMark = () => {
  const { t } = useTranslation();
  const isHomePage = false;

  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchProperties = async (page) => {
    try {
      setIsLoading(true);

      const userData = {
        username: TelegramUser.username,
      };

      const response = await fetch(
        `${API_BASE_URL}/user/api/v1/reservation/get/?page=${page}&page_size=${ITEMS_PER_PAGE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
          body: JSON.stringify(userData),
        }
      );
      const data = await response.json();
      const totalItems = data.count;

      setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

      const propertiesWithImages = await Promise.all(
        data.results.map(async (reservation) => {
          const house = reservation.house;
          if (house.houseImage && house.houseImage.length > 0) {
            const imageResponse = await fetch(house.houseImage[0].image, {
              method: "GET",
              headers: {
                "x-api-key": API_KEY,
              },
            });
            const imageBlob = await imageResponse.blob();
            house.houseImageSrc = URL.createObjectURL(imageBlob);
          }
          return { ...reservation, house };
        })
      );

      setProperties((prevProperties) => [
        ...prevProperties,
        ...propertiesWithImages,
      ]);
    } catch (error) {
      console.error("Failed to fetch properties:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchProperties(page);
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        if (!isLoading && page < totalPages) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, page, totalPages]);

  const handlePropertyClick = (event, propertyStatus) => {
    if (
      propertyStatus === "Rejected" ||
      propertyStatus === "Waiting for Approval"
    ) {
      event.preventDefault();
      window.Telegram.WebApp.showAlert(
        `Unauthorized: ${propertyStatus} status`
      );
    }
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <TelegramBackButton isHomePage={isHomePage} />
        {/* <span className="font-bold text-xs" style={{ marginLeft: "8px" }}>
          GuestHome
        </span> */}
      </div>

      <div className="p-4">
        <h3 className="text-sm font-bold">{t("Booked")}</h3>
        {isLoading && page === 1 ? (
          <LoadingAnim />
        ) : properties.length > 0 ? (
          properties.map((property) => (
            <Link
              to={`/approvedDetail/${property.id}`}
              className="w-full flex py-6"
              onClick={(event) => handlePropertyClick(event, property.status)}
              key={property.id}
            >
              <div
                className="property-items flex rounded-lg bg-white"
                style={{ height: "100px" }}
              >
                <div className="property-imgs">
                  <img
                    src={
                      property.house.houseImageSrc
                        ? property.house.houseImageSrc
                        : defaultImage
                    }
                    alt={property.house.title}
                    className="w-full h-full rounded-lg"
                  />
                </div>
                <div className="property-details w-full py-1 flex">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="text-sm text-black font-bold">
                        {property.house.title}
                      </h3>
                      <p
                        className="text-xs text-gray-500 py-1"
                        style={{ fontSize: "8px" }}
                      >
                        {property.house.sub_description}
                      </p>
                      <p className="text-xs text-gray-500 py-1 truncate">
                        {t("posted by")}
                        <b>@{property.house.postedBy.userAccount.first_name}</b>
                      </p>
                      <p className="text-xs py-1">
                        <b>{property.house.price} ETB</b> / day
                      </p>
                      <p className="text-xs text-gray-500 py-1">
                        {t("Booking Status")}:
                      </p>
                    </div>
                    <div className="flex flex-col items-end justify-between h-full">
                      <div className="flex items-center text-yellow-500">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                        >
                          <path d="M12 2l3.09 6.26 6.91.99-5 4.87L18.18 22 12 18.27 5.82 22l1.18-7.88-5-4.87 6.91-.99L12 2z" />
                        </svg>
                        <span className="ml-1 text-xs text-gray-800">
                          {property.house.rating || "4.0"} / 5.0
                        </span>
                      </div>
                      <br />
                      <div
                        className="flex flex-col items-end mt-auto"
                        style={{ marginTop: "25px" }}
                      >
                        <p className="text-xs text-gray-500 mb-2">
                          {property.distance || "-"} {t("km away")}
                        </p>
                        <button
                          className={`text-xs px-3 py-1 text-white ${
                            property.status === "Approved"
                              ? "bg-green-600"
                              : property.status === "Rejected"
                              ? "bg-red-600"
                              : "bg-yellow-500"
                          }`}
                          style={{ borderRadius: "20px" }}
                        >
                          {property.status === "Approved"
                            ? "Approved"
                            : property.status === "Rejected"
                            ? "Rejected"
                            : "Pending"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center px-4 sm:px-0">
            <div className="flex flex-col justify-center items-center">
              <img
                src={NoResult}
                alt="no result found"
                className="mb-4 w-40 sm:w-22 md:w-30 lg:w-34 xl:w-32"
              />
              <span className="w-25">
                {t(
                  "You didn’t booked any Properties. search and book properties"
                )}
                .
              </span>
              <br />
              <Link to="/">
                <button
                  className="text-black text-xs py-3 px-10 rounded-xl"
                  style={{
                    backgroundColor: "#ffff",
                    border: "1px solid black",
                  }}
                >
                  {t("Search properties")}
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default BookMark;
