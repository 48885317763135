import React, { useEffect } from "react";
import { API_BASE_URL, API_KEY } from "../../config.js";
import "../../App.css";
import Header from "./Header/Header";
import TelegramBackButton from "../../components/TelegramBackButton.jsx";
import MenuList from "../../components/MenuList";
import apartment from "../../assets/Icons/apartment.svg";
import guesthouse from "../../assets/Icons/guest-house.svg";
import hotelBedroom from "../../assets/Icons/hotel-bedroom.svg";
import hotelresort from "../../assets/Icons/hotel&resort.svg";
import pension from "../../assets/Icons/pension.svg";
import houses from "../../assets/Icons/houses.svg";
import private_room from "../../assets/Icons/privateroom.svg";
import shared_spaces from "../../assets/Icons/shared-space.svg";
import warehouse from "../../assets/Icons/warehouse.svg";
import hall from "../../assets/Icons/hall.svg";
import other from "../../assets/Icons/other.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TelegramUser from "../../TelegramUser.js";

const Home = () => {
   const { t, i18n } = useTranslation();
   const isHomePage = true;

   <TelegramBackButton isHomePage={isHomePage} />;

   useEffect(() => {
    
     // Fetch user's preferred language 
     const fetchPreferredLanguage = async () => {
       try {
         const response = await fetch(
           `${API_BASE_URL}/user/api/v1/language/?username=${TelegramUser.username}`,
           {
             method: "GET",
             headers: {
               "Content-Type": "application/json",
               "x-api-key": API_KEY,
             },
           }
         );

         if (response.ok) {
           const data = await response.json();
           const preferredLanguage = data.language;

           // Change the app's language to the preferred language
           i18n.changeLanguage(preferredLanguage);
         } else {
           console.error(
             "Failed to fetch preferred language:",
             response.statusText
           );
         }
       } catch (error) {
         console.error("Error:", error);
       }
     };

     fetchPreferredLanguage();
   }, [i18n]);

  return (
    <>
      <Header />
      <div className="p-4">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div className="app">
            <Link to="/lists/Hotel and Resort" className="block">
              <MenuList image={hotelresort} title={t("Hotel and Resort")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/House" className="block">
              <MenuList image={houses} title={t("Houses")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/pension">
              <MenuList image={pension} title={t("Pension")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/Apartment">
              <MenuList image={apartment} title={t("Apartment")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/private Room">
              <MenuList image={private_room} title={t("Private Room")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/warehouse">
              <MenuList image={warehouse} title={t("Warehouses")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/shared space">
              <MenuList image={shared_spaces} title={t("Shared spaces")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/Hall">
              <MenuList image={hall} title={t("Hall")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/guest house">
              <MenuList image={guesthouse} title={t("Guest House")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/hotel bedroom">
              <MenuList image={hotelBedroom} title={t("Hotel Bedroom")} />
            </Link>
          </div>
          <div className="app">
            <Link to="/lists/Other">
              <MenuList image={other} title={t("Other")}/>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
