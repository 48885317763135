import { useEffect } from "react";

const TelegramBackButton = ({ isHomePage }) => {
  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handleBackButtonClick = () => {
      if (isHomePage) {
        tg.close(); // Close the Mini App on the home page
      } else {
        if (window.history.length > 1) {
          window.history.back(); // Navigate back to the previous page
        } else {
          tg.close(); // Fallback to closing the Mini App if no history exists
        }
      }
    };

    tg.BackButton.show();
    tg.BackButton.onClick(handleBackButtonClick);

    return () => {
      tg.BackButton.hide();
      tg.BackButton.onClick(null); // Cleanup listener
    };
  }, [isHomePage]);

  return null; // No UI component
};

export default TelegramBackButton;
