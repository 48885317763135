import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../App.css";
import "./DetailList.css";
import TelegramBackButton from "../../components/TelegramBackButton.jsx";
import NoResult from "../../assets/inbox_empty.png";
import defaultImage from "../../assets/default.png";
import SearchHouse from "../../components/SearchHouse";
import Trending from "../Trending/Trending";
import { Link } from "react-router-dom";
import { API_BASE_URL, API_KEY } from "../../config.js";
import LoadingAnim from "../../components/LoadingAnim";
import LazyLoad from "react-lazyload";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation } from "react-i18next";

const ITEMS_PER_PAGE = 10;

const DetailList = () => {
  const { t } = useTranslation();
  const isHomePage = false;

  const [filteredProperties, setFilteredProperties] = useState([]);
  const { catagory } = useParams();
  const [properties, setProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handleFilter = (searchTerm) => {
    if (searchTerm.trim() === "") {
      setFilteredProperties(properties);
    } else {
      const lowercasedTerm = searchTerm.toLowerCase();
      const filtered = properties.filter(
        (property) =>
          property.title.toLowerCase().includes(lowercasedTerm) ||
          property.sub_description.toLowerCase().includes(lowercasedTerm) ||
          property.city.toLowerCase().includes(lowercasedTerm) ||
          property.specificAddress.toLowerCase().includes(lowercasedTerm)
      );
      setFilteredProperties(filtered);
    }
  };

  const fetchProperties = async (page) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/user/api/v1/properties/?catagory=${catagory}&page=${page}&page_size=${ITEMS_PER_PAGE}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
        }
      );

      const data = await response.json();

      // total pages and ITEMS_PER_PAGE
      const totalItems = data.count;
      setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

      const propertiesWithImages = data.results.map((property) => {
        if (property.houseImage && property.houseImage.length > 0) {
          const imageUrls = property.houseImage.map(
            (imageObj) => imageObj.image
          );
          property.houseImageSrc = imageUrls;
        }
        return property;
      });

      setProperties((prevProperties) => {
        const mergedProperties = [...prevProperties, ...propertiesWithImages];

        const uniqueProperties = Array.from(
          new Map(mergedProperties.map((item) => [item.id, item])).values()
        );

        return uniqueProperties;
      });

      setFilteredProperties((prevProperties) => {
        const mergedProperties = [...prevProperties, ...propertiesWithImages];
        const uniqueProperties = Array.from(
          new Map(mergedProperties.map((item) => [item.id, item])).values()
        );

        return uniqueProperties;
      });
    } catch (error) {
      console.error("Failed to fetch properties:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setProperties([]);
    setPage(1);
    fetchProperties(1); // Fetch initial properties
  }, [catagory]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        if (!isLoading && page < totalPages) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading, page, totalPages]);

  useEffect(() => {
    if (page > 1) {
      fetchProperties(page);
    }
  }, [page]);

  return (
    <>
      <TelegramBackButton isHomePage={isHomePage} />
      <br />

      <SearchHouse onFilter={handleFilter} onApplyFilters={setProperties} />

      <div>
        <h1 className="text-sm font-bold mb-4 text-left px-6">
          {t("Most Popular")}
        </h1>
        <Trending />
      </div>

      <div className="p-4">
        <h3 className="text-sm font-bold">{t("Nearby Your Location")}</h3>
        {isLoading && page === 1 ? (
          <LoadingAnim />
        ) : filteredProperties.length > 0 ? (
          filteredProperties.map((property) => (
            <div
              key={property.id}
              className="property-item rounded-lg overflow-hidden bg-white mb-6"
            >
              <Link to={`/detail/${property.id}`} className="block">
                <div className="property-image w-full">
                  {property.houseImageSrc &&
                  property.houseImageSrc.length > 0 ? (
                    <Swiper
                      spaceBetween={4}
                      slidesPerView={1}
                      pagination={{ clickable: true }}
                      modules={[Pagination]}
                      className="property-swiper"
                    >
                      {property.houseImageSrc.map((src, index) => (
                        <SwiperSlide key={index}>
                          <LazyLoad height={350} offset={100}>
                            <img
                              src={src}
                              alt={`Property ${index + 1}`}
                              className="w-full"
                              style={{ borderRadius: "5px", height: "350px" }}
                            />
                          </LazyLoad>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    <img
                      src={defaultImage}
                      alt={property.title}
                      className="w-full"
                      style={{ borderRadius: "5px", height: "350px" }}
                    />
                  )}
                </div>

                <div className="p-1">
                  <div className="flex justify-between items-center">
                    <h3 className="text-md font-bold truncate">
                      {property.title}
                    </h3>
                    <div className="flex items-center">
                      <i
                        className="fa fa-star"
                        style={{ color: "#FFA500" }}
                      ></i>
                      <span className="text-xs text-gray-500">4.0/5.0</span>
                    </div>
                  </div>

                  <p className="text-xs text-gray-500">
                    {property.sub_description}
                  </p>
                  <p className="text-xs text-gray-500 mt-1">
                    Posted by
                    <b>
                      @{property.postedBy?.userAccount?.first_name}{" "}
                      {property.postedBy?.userAccount?.last_name}
                    </b>
                  </p>

                  <div className="flex justify-between items-center mt-1">
                    <p className="text-xs font-semibold">
                      {property.price} {t("ETB")} / {t("day")}
                    </p>
                    <p className="text-xs text-gray-400">
                      {property.distance} {t("km away")}
                    </p>
                  </div>

                  <div className="flex justify-between mt-1">
                    <p className="text-xs text-gray-500 flex items-center">
                      <i className="fa fa-map-marker mr-1"></i>
                      {property.city} , {property.specificAddress}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center text-center px-4 sm:px-0">
            <div className="flex flex-col justify-center items-center">
              <img
                src={NoResult}
                alt="no result found"
                className="mb-4 w-40 sm:w-22 md:w-30 lg:w-34 xl:w-32"
              />
            </div>
          </div>
        )}
        {isLoading && page > 1 && <LoadingAnim />}
        {/* Show loading animation when loading more properties */}
      </div>
    </>
  );
};

export default DetailList;
