import React, { useEffect, useState, useCallback } from "react";
import "./Trending.css";
import Trending1 from "../../assets/trending/trending1.jpg";
import TrendingCard from "./TrendingCard";
import { API_BASE_URL, API_KEY } from "../../config.js";
import LoadingAnim from "../../components/LoadingAnim.jsx";
import { t } from "i18next";

const ITEMS_PER_PAGE = 10;

const Trending = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchProperties = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${API_BASE_URL}/user/api/v1/most_reserved_house/?page=${page}&page_size=${ITEMS_PER_PAGE}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": API_KEY,
          },
        }
      );
      if (!response.ok) {
        // hide the whole trending section
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const totalItems = data.count;

      if (totalItems === 0) {
        throw new Error(t("No trending data available"));
      }

      setTotalPages(Math.ceil(totalItems / ITEMS_PER_PAGE));

      const formattedData = data.results.map((house) => ({
        id: house.id,
        name: house.title,
        price: house.price + " ETB / day",
        rating: house.postedBy.rating,
        postedBy:
          house.postedBy?.userAccount?.first_name +
          " " +
          house.postedBy?.userAccount?.last_name,
        location: house.city + " " + house.specificAddress,
        amenities: house.sub_description,
        images: house.houseImage?.map((img) => img.image) || [Trending1],
      }));

      setProperties((prevProperties) => [...prevProperties, ...formattedData]);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [page]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        if (!loading && page < totalPages) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, page, totalPages]);

  // Hide the section if there is an error or no properties
  if (error || (!loading && properties.length === 0)) {
    return null;
  }
  
  // if (error) {
  //   return (
  //     <div>
  //       {t("Error")}: {error}
  //     </div>
  //   );
  // }

  return (
    <div className="overflow-x-auto flex space-x-4 p-4">
      {loading ? (
        <div className="flex justify-center items-center w-full h-full">
          <LoadingAnim />
        </div>
      ) : (
        properties.map((property, index) => (
          <TrendingCard key={index} property={property} />
        ))
      )}
    </div>
  );
};

export default Trending;
