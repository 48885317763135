import React from "react";
import { API_BASE_URL, API_KEY } from "../../config.js";
import { useTranslation } from "react-i18next";
import TelegramBackButton from "../../components/TelegramBackButton.jsx";
import TelegramUser from "../../TelegramUser.js";

const USER_NAME = TelegramUser.username;

const Settings = () => {
  const { t, i18n } = useTranslation();
  const isHomePage = false;

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    postLanguagePreference(language, USER_NAME);
  };

  const postLanguagePreference = async (language, username) => {
    const langData = {
      language: language,
      username: username,
    };
    try {
      const response = await fetch(`${API_BASE_URL}/user/api/v1/language/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": API_KEY,
        },
        body: JSON.stringify(langData),
      });

      if (!response.ok) {
        window.Telegram.WebApp.showAlert("Error: posting language preference:");
        console.error(
          "Error posting language preference:",
          response.statusText
        );
      } else {
        window.Telegram.WebApp.showAlert(
          "Language preference updated successfully."
        );
        console.log("Language preference updated successfully.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      {/* Back Button */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <TelegramBackButton isHomePage={isHomePage} />
        {/* <span className="font-bold text-xs" style={{ marginLeft: "8px" }}>
          GuestHome
        </span> */}
      </div>

      <div class="bg-white shadow-md shadow-gray-300 rounded-lg overflow-hidden m-4 sm:mx-6 md:mx-8 lg:mx-16">
        <div class="p-4">
          <h3 class="font-bold mb-2 pt-0">{t("Become a Host")}</h3>
          <p class="text-gray-600 mt-2 text-sm">
            {t("Join our community of hosts and start welcoming guests today")}!
          </p>
          <button
            class="mt-4 text-white px-8 py-2 rounded-lg"
            style={{ backgroundColor: "#6938ef" }}
            onClick={() =>
              (window.location.href = "https://t.me/Etguesthome_bot")
            }
          >
            {t("Become a Host")}
          </button>
        </div>
      </div>

      <div className="p-4 px-6">
        <div className="text-gray-700 text-lg font-semibold mb-2">
          {t("Settings")}
        </div>
        <div className="text-gray-500 mb-4">{t("set_preference")}</div>
        <div className="text-gray-800 mb-4">{t("language")}</div>

        {/* Language Dropdown */}
        <div className="relative">
          <select
            className="w-full border rounded-lg p-3 cursor-pointer hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(e) => changeLanguage(e.target.value)}
            defaultValue={i18n.language}
          >
            <option value="en">English</option>
            <option value="am">አማርኛ</option>
            <option value="om">Afan Oromo</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Settings;
