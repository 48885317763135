import React from "react";
import telegram from "../../../../assets/Icons/social_media/telegram.svg"
import facebook from "../../../../assets/Icons/social_media/facebook.svg"
import youtube from "../../../../assets/Icons/social_media/youtube.svg"
import tiktok from "../../../../assets/Icons/social_media/tiktok.svg"
import linkedin from "../../../../assets/Icons/social_media/linkedin.svg"
import instagram from "../../../../assets/Icons/social_media/instagram.svg"
import TelegramBackButton from "../../../../components/TelegramBackButton";
import "./About.css";

const About = () => {
  
  const isHomePage = false;

  return (
    <div className="bg-gray-900 text-white min-h-screen">
      <TelegramBackButton isHomePage={isHomePage} />

      <div className="container mx-auto px-6 py-12 text-center bg-white">
        <h1
          className="text-md md:text-4xl font-semibold"
          style={{ color: "#6938ef" }}
        >
          About us
        </h1>
        <h1 className="text-3xl md:text-4xl font-bold text-black">
          About the company
        </h1>
        <p className="text-xs mt-2 text-black">
          GuestHome PLC is a company that help you to access any reservation
          room at your location.
        </p>
      </div>

      <div
        className="container mx-auto px-6 py-12 text-center"
        style={{ backgroundColor: "#6938ef" }}
      >
        <h1 className="text-3xl md:text-4xl font-bold text-white mt-6 mb-6">
          Contact us:
        </h1>
        <p className="text-md mt-2 text-white">0904764476</p>
        <p className="text-md mt-2 text-white">mail@etguesthomes.com</p>
      </div>

      <div
        className="container mx-auto px-6 py-4 text-center"
        style={{ backgroundColor: "#ffff" }}
      >
        <h4 className="text-md md:text-xs font-bold text-black mt-6 mb-6">
          Checkout our social media
        </h4>
        <div className="flex items-center justify-between p-2 w-full">
          <div className="w-full">
            <a
              href="https://www.youtube.com/@GuestHomePLC"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="text-black text-xs py-2 px-4 font-semibold rounded-lg w-full border border-gray-300 flex items-center justify-center space-x-2"
                style={{ backgroundColor: "#ffff" }}
              >
                <img src={youtube} alt="youtube icon" className="w-4 h-4" />
                <span>Ethomes</span>
              </button>
            </a>
            {/* <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="text-black text-xs py-2 px-4 mt-2 font-semibold rounded-lg w-full border border-gray-300 flex items-center justify-center space-x-2"
                style={{ backgroundColor: "#ffff" }}
              >
                <img src={facebook} alt="facebook icon" className="w-4 h-4" />
                <span>Ethomes</span>
              </button>
            </a> */}
            <a
              href="https://t.me/guesthomeethiopia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="text-black text-xs py-2 px-4 mt-2 font-semibold rounded-lg w-full border border-gray-300 flex items-center justify-center space-x-2"
                style={{ backgroundColor: "#ffff" }}
              >
                <img src={telegram} alt="telegram icon" className="w-4 h-4" />
                <span>Ethomes</span>
              </button>
            </a>
            {/* <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="text-black text-xs py-2 px-4 mt-2 font-semibold rounded-lg w-full border border-gray-300 flex items-center justify-center space-x-2"
                style={{ backgroundColor: "#ffff" }}
              >
                <img src={instagram} alt="instagram icon" className="w-4 h-4" />
                <span>Ethomes</span>
              </button>
            </a> */}
            {/* <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="text-black text-xs py-2 px-4 mt-2 font-semibold rounded-lg w-full border border-gray-300 flex items-center justify-center space-x-2"
                style={{ backgroundColor: "#ffff" }}
              >
                <img src={linkedin} alt="linkedin icon" className="w-4 h-4" />
                <span>Ethomes</span>
              </button>
            </a> */}
            <a
              href="https://www.tiktok.com/@etguesthome?_t=8s4XH87Ncvd&_r=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                className="text-black text-xs py-2 px-4 mt-2 font-semibold rounded-lg w-full border border-gray-300 flex items-center justify-center space-x-2"
                style={{ backgroundColor: "#ffff" }}
              >
                <img src={tiktok} alt="tiktok icon" className="w-4 h-4" />
                <span>Ethomes</span>
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="container mx-auto px-6 py-6 text-left">
        <h1 className="text-2xl md:text-3xl font-bold text-white text-left">
          Visit our office
        </h1>
        <p className="text-lg mt-2">Find us at these location.</p>
      </div>

      {/* Location Section */}
      <div className="py-2">
        <div className="container mx-auto px-6">
          <h2 className="text-sm uppercase tracking-wide font-semibold text-gray-400">
            Our location
          </h2>
          <h3 className="text-2xl font-bold mt-2">Addis Ababa</h3>
          <p className="mt-4 text-md font-semibold">
            Ayat, Kibir damena yahiwe nisi building 7th floor
          </p>

          {/* Google Map */}
          <div className="mt-8">
            <div className="relative h-64 w-full rounded-lg overflow-hidden">
              <iframe
                title="Map"
                className="absolute inset-0 w-full h-full"
                src={`https://maps.google.com/maps?q=9.03167,38.87694&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                style={{ border: 0 }}
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <footer className="bg-white py-6 text-center text-gray-400">
        © 2024 GuestHome. All rights reserved.
      </footer>
    </div>
  );
};

export default About;
